import "./index.css";
import Header from "./components/header/header.jsx";
import Navbar from "./components/nav/navbar.jsx";
import Experience from "./components/experience/experience.jsx";
import Skills from "./components/skills/skills.jsx";
import About from "./components/about/about.jsx";
import Contact from "./components/contact/contact";

const App = () => {
  return (
    <body>
      <Navbar />
      <Header />
      <Experience />
      <Skills />
      <About />
      <Contact />
    </body>
  );
};

export default App;
