import React from "react";
import { useState, useEffect } from "react";
import "./navbar.css";
import { FcCalculator } from "react-icons/fc";
import CV from "../../assets/alberto_casillas.pdf";

const Navbar = () => {
  const [sticky, setSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });
  return (
    <nav className={`${sticky ? "sticky" : ""}`}>
      <FcCalculator size={37} class="icon" />
      <ul className="nav_links">
        <li>
          <a href="#">Home</a>
        </li>
        <li>
          <a href="#experience">Experience</a>
        </li>
        <li>
          <a href="#skills">Skills</a>
        </li>
        <li>
          <a href="#contact">Contact</a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/in/alberto-casillas-b55564264/"
            target="_blank"
          >
            LinkedIn
          </a>
        </li>
        <li>
          <a href={CV} download>
            Download My Resume
          </a>
        </li>
      </ul>
      <div className="dropdown">
        <a href="#" className="cta">
          <button className="connect-button">Connect</button>
        </a>
        <div className="dropdown-menu">
          <a href="tel:619-344-1424">(619)344-1424</a>
          <a href="mailto:albertocasilla134@gmail.com">Email me!</a>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
