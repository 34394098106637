import React from "react";
import "./header.css";
import AL from "../../assets/al-pic.jpg";

const Header = () => {
  return (
    <header>
      <div className="header" id="header">
        <div className="header_container">
          <div className="bio">
            <h1>Alberto Casillas</h1>
            <p>
              A hardworking and driven student nearing graduation. Presently
              working for Daeda Stream as an Accountant and Client Service
              Manager looking to transition to Finance Manager. I am ready to
              use my accrual accounting, relationship building, and
              organizational skills to create a stable financial platform for
              the company and clients to improve their overall experience.
            </p>
          </div>
          <div className="me">
            <img src={AL} alt="me" />
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
