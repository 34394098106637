import React from "react";
import "./about.css";
import bero from "../../assets/albero.jpg";

const About = () => {
  return (
    <div className="about" id="about">
      <h1>More About Me</h1>
      <div className="about-container">
        <div className="about-education">
          <h2>Education</h2>
          <h3>
            San Diego State University College of Business- Bachelor's Degree in
            Accounting
          </h3>
          <h5>August 2023 - June 2025</h5>
          <h3>
            Mesa Community College, San Diego - Associate Degree in Science
          </h3>
          <h5>August 2019 - June 2023</h5>
        </div>
        <img className="bero" src={bero} alt="bero" />
        <div className="about-accolades">
          <h2>Accolades</h2>
          <h3>Cliff Robertson Acting Award</h3>
          <p>
            Recipient of the Cliff Robertson Acting Award given by La Jolla High
            School to outstanding students that portray excellent leadership and
            team management skills
          </p>
        </div>
      </div>
    </div>
  );
};
export default About;
