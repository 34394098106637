import React from "react";
import "../experience/experience.css";
import CV from "../../assets/alberto_casillas.pdf";

const Experience = () => {
  return (
    <div className="experience" id="experience">
      <h1>My Experience</h1>
      <div className="experience-container">
        <div className="experience-details-ap">
          <h2>Arrow Plumbing and Drain Services</h2>
          <h5>Client Relationship Management</h5>
          <h5>June 2019 - Present</h5>
          <p>
            Interacting with 20+ customers and dispatched technicians daily to
            foster security-of-service as well as to develop powerful
            project/team management skills. Personally attending to expansion of
            the company by working with technicians to cultivate client
            relationships and strong company representation.
          </p>
        </div>
        <div className="experience-details-ap2">
          <h2>Arrow Plumbing and Drain Services</h2>
          <h5>Software Expansion</h5>
          <h5>June 2019 - Present</h5>
          <p>
            Organizing meetings with software developers to create software to
            manage projects, teams, payroll, dispatching, and office
            communications. Saw to pairing of developers with current Office
            Manager to ensure proper pay and production coordination. The
            intended result of the collaboration is an application that
            functions on any platform allowing technicians to view jobs and
            write invoices.
          </p>
        </div>
        <div className="experience-details-abb">
          <h2>AirBnB</h2>
          <h5>Host</h5>
          <h5>May 2022 - Present</h5>
          <p>
            Personally attending to scheduling alongside working with the
            maintenance and cleaning team to ensure they have the tools and
            materials necessary to ensure maximized guest comfort during their
            stay.
          </p>
        </div>
        <div className="experience-details-ds">
          <h2>Daeda Stream</h2>
          <h5>Client Service Manager</h5>
          <h5>December 2022 - Present</h5>
          <p>
            Collaborating with two well-versed Software Developers in a startup
            environment. Conducting thorough user-research to pursue a dynamic
            customer base. Maintaining records of current/past clients as well
            as project progress. Personally accountable with respect to the
            functions of the Accounting Department.
          </p>
        </div>
      </div>
    </div>
  );
};
export default Experience;
