import React from "react";
import "./skills.css";

const Skills = () => {
  return (
    <div className="skills" id="skills">
      <h1>My Skills</h1>
      <div className="skills-container">
        <div className="skills-practical">
          <h2>Practical Skills</h2>
          <ul>
            <li>
              Editing, proofreading, and composing emails, reports, memos, and
              business forms
            </li>
            <li>Fluent in English and Spanish</li>
          </ul>
        </div>
        <div className="skills-technical">
          <h2>Technical Skills</h2>
          <ul>
            <li>Excel</li>
            <li>Microsoft Word</li>
            <li>Microsoft 360</li>
            <li>Google Sheets/Docs/Slides</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Skills;
