import React from "react";
import "./contact.css";

const Contact = () => {
  return (
    <div className="contact" id="contact">
      <h1>Contact Form</h1>
      <div className="contact-container">
        <form
          action="https://formsubmit.co/77728d87447fa042e56836a8a399c2d8"
          method="POST"
          className="contact-form">
          <input type="text" name="name" required placeholder="Name" />
          <input type="email" name="email" required placeholder="Email" />
          <input type="hidden" name="_captcha" value="false" />
          <input
            type="hidden"
            name="_subject"
            value="New Email from Website!"
          />
          <input
            type="hidden"
            name="_cc"
            value="albertocasillas134@yahoo.com"
          />
          <input
            type="hidden"
            name="_autoresponse"
            value="Thank you so much for reaching out to me!  I'll be sure to reply to your message as soon as I can.  Until then have a great day and I look forward to talking with you!"
          />
          <textarea
            name="subject"
            id="subject"
            cols="30"
            rows="10"
            placeholder="Subject"></textarea>
          <button className="form-submit" type="submit">
            Send
          </button>
        </form>
      </div>
    </div>
  );
};
export default Contact;
